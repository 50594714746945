import React, { FC } from "react";
import { graphql } from "gatsby";

import { NewsletterPageQuery } from "lib/cms";

import PageWrapper from "components/PageWrapper";
import NewsletterForm from "components/NewsletterForm";
import PortableText from "components/PortableText";

import NotFoundPage from "./404";
import MetaData from "components/MetaData";

interface Props {
  data: NewsletterPageQuery;
  location: Location;
}

const NewsletterPage: FC<Props> = ({ data: { page } }) => {
  return page ? (
    <PageWrapper backgroundColor={page.backgroundColor}>
      <MetaData title="Newsletter" />
      <PortableText blocks={page.content} indent={false} />
      <NewsletterForm />
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query NewsletterPage {
    page: sanityNewsletter {
      backgroundColor
      content: _rawContent
    }
  }
`;

export default NewsletterPage;
