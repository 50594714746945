import React, { useState } from "react";

const NewsletterForm = () => {
  const [state, setState] = useState({
    EMAIL: "",
    b_95be591e83512c1dd903d1c62_3697d33b65: "",
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };

  return (
    <form
      action="https://world.us20.list-manage.com/subscribe/post?u=95be591e83512c1dd903d1c62&amp;id=3697d33b65"
      className="flex flex-wrap md:flex-nowrap mt-9"
      method="post"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <input
        className="flex-grow bg-transparent border-black border-solid font-bold outline-none"
        type="email"
        value={state["EMAIL"]}
        name="EMAIL"
        style={{ borderBottomWidth: "0.3rem" }}
        onChange={handleChange}
      />
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_95be591e83512c1dd903d1c62_3697d33b65"
          tabIndex={-1}
          value={state["b_95be591e83512c1dd903d1c62_3697d33b65"]}
          onChange={handleChange}
        />
      </div>
      <button
        className="bg-black text-white text-medium md:text-base font-bold px-4 pb-1 md:pb-1.8 mt-2 md:mt-0 md:ml-6 w-full md:w-auto leading-0"
        type="submit"
        name="subscribe"
      >
        Subscribe
      </button>
    </form>
  );
};

export default NewsletterForm;
